// import { capitalCase } from 'change-case';
// @mui
import { styled } from '@mui/material/styles';
import { Box,  Stack,  Alert ,Container, Typography } from '@mui/material';
// routes
// hooks
// components
import Page from '../../components/Page';
import LoginForm from '../../components/LoginForm';
// sections
// import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));





const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {



  return (
    <Page title="Login">
      <RootStyle>
       

        

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Client
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>

            
            </Stack>

            <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>client</strong> / password :<strong> 12345678</strong>
            </Alert>

            <LoginForm />

         
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
