/* eslint-disable no-constant-condition */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Login from '../../pages/Auth/Login';
import { useSelector } from '../../redux/store';


AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
   const {isAuthenticated}=useSelector((state)=>state.Login)

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}