import axios from 'axios';
// config
// import { BaseUrl } from '../Utilities/Config';

// ----------------------------------------------------------------------
const BaseUrl = "https://achorcarecenter.dev-hi.xyz/api";
const axiosInstance = axios.create({
  baseURL: BaseUrl,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
