import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import LoginSlice from "./Auth/LoginSlice";


const rootPersistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
   Login: LoginSlice,
});

export { rootPersistConfig, rootReducer };
