import { Button, Stack, TextField } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { useState } from 'react';
import { dispatch} from '../redux/store';
import { Logins } from '../redux/Auth/LoginSlice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  // const [showPassword, setShowPassword] = useState(false);
  // const Naviagte = useNavigate()

  const [Data, setdata] = useState({
    name: '',
    password: '',
  });
  const handlechange = (event) => {
    const { name, value } = event.target;
    setdata((prevData) => ({
      ...prevData,

      [name]: value,
    }));
  };
 

  const onSubmit = async () => {
     dispatch(Logins(Data));
    // if (isAuthenticated) {
    //   Naviagte( '/dashboard/one');
    // }
  };

  return (
    <>
      <Stack spacing={3}>
        <Toaster position="top-right" reverseOrder={false} />
        <TextField label="Email" variant="outlined" fullWidth value={Data.name} name="name" onChange={handlechange} />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          name="password"
          type="password"
          value={Data.password}
          onChange={handlechange}
        />
        <Button size="large" onClick={onSubmit} variant="contained">
          Login
        </Button>
      </Stack>
    </>
  );
}
